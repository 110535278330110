import { useState, useEffect, useRef } from "react";
import poolApi from "../../services/poolApi";
import usersApi from "../../services/usersApi";

import { useTonAddress } from "@tonconnect/ui-react";
import { useTimer } from "react-timer-hook";
import { AppDispatch } from "../../app/store";
import { useDispatch } from "react-redux";
import {
  CURRENCY,
  fetchBalance,
} from "../../app/features/currency/currencySlice";
import * as amplitude from "@amplitude/analytics-browser";
import { useTelegram } from "../../hooks/useTelegram";
const tapGoal = 100;

interface Click {
  id: number;
  x: number;
  y: number;
}

const useEligibility = () => {
  const { userId } = useTelegram();
  const dispatch = useDispatch<AppDispatch>();
  const [clicks, setClicks] = useState<Click[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [expiryTimestamp, setExpiryTimestamp] = useState<Date | null>(null);
  const [tapCount, setTapCount] = useState(0);
  const address = useTonAddress();
  const wallet = useTonAddress();
  const [animDir, setAnimDir] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [counter, setCounter] = useState<number>(0);
  const { seconds, minutes, hours, isRunning, start, pause, restart } =
    useTimer({
      expiryTimestamp: new Date(),
      autoStart: true,
    });
  const [isClaiming, setIsClaiming] = useState(false);
  const [claimError, setClaimError] = useState<string | null>(null);

  const handleClick = (clientX: number, clientY: number) => {
    const newClick: Click = {
      id: Date.now(),
      x: clientX,
      y: clientY,
    };

    setClicks((prevClicks) => [...prevClicks, newClick]);

    setTimeout(() => {
      setClicks((prevClicks) =>
        prevClicks.filter((click) => click.id !== newClick.id)
      );
    }, 700);

    const sxt = (newClick.x - 125) / 100;
    const syt = (newClick.y - 125) / 100;
    const skew =
      Math.round(Math.abs(1.2 - Math.abs(sxt) - Math.abs(syt)) * 10) / 10;

    const dir = Math.abs(sxt) - Math.abs(syt);
    console.log(sxt, syt, dir, skew);

    if (dir > 0) {
      if (sxt > 0) {
        if (skew >= 0.3) {
          syt >= 0
            ? setAnimDir(`[transform:matrix(1,0,0.1,1,10,10)]`)
            : setAnimDir(`[transform:matrix(1,-0.1,0,1,10,-10)]`);
        } else {
          setAnimDir(`[transform:matrix(1.05,0,0,0.95,10,0)]`);
        }
      } else {
        if (skew >= 0.3) {
          syt >= 0
            ? setAnimDir(`[transform:matrix(1,0,-0.1,1,-10,10)]`)
            : setAnimDir(`[transform:matrix(1,0.1,0,1,-10,-10)]`);
        } else {
          setAnimDir(`[transform:matrix(1.05,0,0,0.95,-10,0)]`);
        }
      }
    } else {
      if (syt > 0) {
        setAnimDir(`[transform:matrix(0.95,0,0,1.05,0,10)]`);
      } else {
        setAnimDir(`[transform:matrix(0.95,0,0,1.05,0,-10)]`);
      }
    }
    // console.log(clientX - btnLeft, clientY - btnTop);
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 50);
  };
  const touchHandledRef = useRef(false);

  const handleTouchStart = (event: React.TouchEvent<HTMLButtonElement>) => {
    if (event.currentTarget.disabled) return;
    touchHandledRef.current = true;
    for (let i = 0; i < event.touches.length; i++) {
      handleClick(
        event.touches[i].clientX -
          event.currentTarget.getBoundingClientRect().left,
        event.touches[i].clientY -
          event.currentTarget.getBoundingClientRect().top
      );
    }
    if (counter < tapGoal) {
      setCounter((prevCounter) =>
        prevCounter + event.touches.length > tapGoal
          ? tapGoal
          : prevCounter + event.touches.length
      );
    }
    if (counter >= tapGoal) {
      handleClaim();
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.disabled) return;
    if (!touchHandledRef.current) {
      handleClick(
        event.clientX - event.currentTarget.getBoundingClientRect().left,
        event.clientY - event.currentTarget.getBoundingClientRect().top
      );
      if (counter < tapGoal) {
        setCounter((prevCounter) =>
          prevCounter + 1 > tapGoal ? tapGoal : prevCounter + 1
        );
      }
      if (counter >= tapGoal) {
        handleClaim();
      }
    }
    touchHandledRef.current = false;
  };

  const checkEligibility = async () => {
    try {
      const lastClaimDateStr = await poolApi.getLastClaimDate(wallet);
      const lastClaimDate = new Date(lastClaimDateStr.lastClaimDate);
      const currentTime = new Date();
      const timeDiff = currentTime.getTime() - lastClaimDate.getTime();
      const hoursDiff = timeDiff / (1000 * 60 * 60);
      if (hoursDiff >= 12 && tapGoal >= tapCount) {
        setIsDisabled(false);
        setExpiryTimestamp(null);
        pause();
      } else {
        const nextClaimDate = new Date(
          lastClaimDate.getTime() + 12 * 60 * 60 * 1000
        );
        setExpiryTimestamp(nextClaimDate);
        restart(nextClaimDate, true);
      }
    } catch (error) {
      console.error("Error checking eligibility:", error);
    }
  };

  const handleTap = () => {
    setTapCount((prev) => {
      const newCount = prev + 1;
      if (newCount >= tapGoal) {
        handleClaim();
      }
      return newCount;
    });
  };

  const handleClaim = async () => {
    if (isClaiming) return;

    setIsClaiming(true);
    setClaimError(null);

    try {
      await poolApi.claimPoints(wallet);
      setIsDisabled(true);
      setTapCount(0);
      checkEligibility();

      dispatch(fetchBalance(address));

      amplitude.track("Claim Tap Button", {
        wallet: wallet,
        userId: userId ? userId : null,
      });
    } catch (error) {
      console.error("Error claiming points:", error);
      setClaimError("Failed to claim points. Please try again.");
    } finally {
      setIsClaiming(false);
    }
  };

  useEffect(() => {
    checkEligibility();
  }, [wallet, pause, restart]);

  return {
    counter,
    clicks,
    handleTouchStart,
    handleButtonClick,
    isDisabled,
    expiryTimestamp,
    seconds,
    minutes,
    hours,
    isRunning,
    handleTap,
    tapCount,
    tapGoal,
    handleClaim,
    isClicked,
    animDir,
    isClaiming,
    claimError,
  };
};

export default useEligibility;
